<template>
  <div class="cj-field days-names mb-16">
    <div v-for="day in days" :key="day.name" ref="daysNames">
      <div class="days-header mb-8" :class="{ 'days-name': day.isEnabled }">
        <label :for="`day-${day.name}`">
          {{ getDayName(day.name) }}
        </label>
        <input
          type="checkbox"
          :id="`day-${day.name}`"
          :key="day.name"
          class="checkbox-input"
          :class="{ disabled: isDayDisabled(day.isEnabled) }"
          :checked="day.isEnabled"
          @change="changeValueForSpecificDay(day.name, !day.isEnabled, 'isEnabled')"
          :disabled="isDayDisabled(day.isEnabled)"
        />
      </div>

      <div class="text-left" v-if="day.isEnabled">
        <div class="axis-label mb-8">{{ $t("trainingDays.dailyLimit") }}</div>
        <div class="select mb-8">
          <select
            type="text"
            @change="event => changeValueForSpecificDay(day.name, Number(event.target.value), 'minutesLimit')"
          >
            <option
              v-for="option in durationOptions"
              :key="option.value"
              :value="option.value"
              :selected="option.value === day.minutesLimit"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
        <div v-if="day.minutesLimit !== -1" class="axis-label" style="text-transform: none; margin-bottom: 6px ">
          <p>⚠️ {{ $t("trainingDays.automaticIs") }}</p>
          <p>{{ $t("trainingDays.recommended") }}</p>
        </div>
        <div class="cj-label-title">
          <label class="mr-8" :for="`longDay-${day.name}`"> {{ $t("longRides.input") }} </label>
          <input
            type="checkbox"
            :id="`longDay-${day.name}`"
            :key="day.name"
            class="checkbox-input"
            :checked="day.isBestForLongRide"
            @change="changeValueForSpecificDay(day.name, !day.isBestForLongRide, 'isBestForLongRide')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex"
import { DEFAULT_DAILY_MINUTES } from "@/defaults"

export default {
  name: "TrainingDays",

  data() {
    return {
      durationOptions: [
        { name: "Automatic", value: DEFAULT_DAILY_MINUTES },
        ...[20, 30, 45, 60, 75, 90, 120, 180].map(el => ({ value: el, name: `${el} Minutes` }))
      ]
    }
  },

  computed: {
    ...mapState("plan", ["values"]),
    ...mapGetters("plan", ["getBlockConfigValue"]),

    days() {
      return this.getBlockConfigValue("days")
    }
  },
  methods: {
    ...mapActions("plan", ["updateActiveBlockConfigValue"]),

    isDayDisabled(isEnabled) {
      return this.days?.length === 1 && isEnabled
    },
    getDayName(name) {
      return this.values.workoutDaysValues.find(d => d.value === name).name
    },
    changeValueForSpecificDay(dayName, value, field) {
      this.updateActiveBlockConfigValue({
        name: "days",
        value: this.days.map(day => {
          if (day.name === dayName) {
            return {
              ...day,
              [field]: value
            }
          }
          return day
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/theme.scss";
.days-names {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  text-align: center;
  gap: 2px;

  > div {
    background-color: white;
    padding: 16px 16px;
  }
}
.axis-label {
  font-size: 12px;
  fill: #b7b7b7;
  text-transform: capitalize;
}
.days-name {
  color: $dark-blue;
  font-weight: 600;
}
.days-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 50px;
}
.disabled {
  cursor: not-allowed;
}
</style>
