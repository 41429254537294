import { render, staticRenderFns } from "./TrainingDays.vue?vue&type=template&id=60123ca8&scoped=true&"
import script from "./TrainingDays.vue?vue&type=script&lang=js&"
export * from "./TrainingDays.vue?vue&type=script&lang=js&"
import style0 from "./TrainingDays.vue?vue&type=style&index=0&id=60123ca8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60123ca8",
  null
  
)

export default component.exports